import axios, { AxiosResponse } from 'axios';

interface Dict<T> {
    [key: string]: T
}

export interface ResponseSchema {

    data: {
        [key : string] : any,
    },
    twilSuccess? : boolean;

}

interface EategoAxiosResp<T extends ResponseSchema> extends AxiosResponse<T> {}

export abstract class AxiosController {
    protected async axiosGET<T extends ResponseSchema>(
        path: string, params: Dict<any>,
    ) : Promise<T> {
        const axiosResp : EategoAxiosResp<T> = await axios.get(path, {
            params: params,
        }).catch((error) => {
            throw error;
        });

        const respData : T = this.loadAxiosResp(axiosResp);
        return respData;
    }

    protected async axiosPOST<T extends ResponseSchema>(
        path: string, body: Dict<any>,
    ) : Promise<T> {
        const axiosResp : EategoAxiosResp<T> = await axios.post(path, body).catch((error) => {
            throw error;
        });

        const respData : T = this.loadAxiosResp(axiosResp);
        return respData;
    }

    protected async axiosPUT<T extends ResponseSchema>(
        path: string, body: Dict<any>,
    ) : Promise<T> {
        const axiosResp : EategoAxiosResp<T> = await axios.put(path, body).catch((error) => {
            throw error;
        });

        const respData : T = this.loadAxiosResp(axiosResp);
        return respData;
    }

    protected async axiosDELETE<T extends ResponseSchema>(
        path: string, params: Dict<any>,
    ) : Promise<T> {
        const axiosResp : EategoAxiosResp<T> = await axios.delete(path, {
            params: params,
        }).catch((error) => {
            throw error;
        });

        const respData : T = this.loadAxiosResp(axiosResp);
        return respData;
    }

    private loadAxiosResp<T extends ResponseSchema>(axiosResp: EategoAxiosResp<T>) : T {
        // Resp Data
        const respData : T = axiosResp.data;

        // Check if response has twil
        const twilSuccess : boolean | undefined = axiosResp.data.twilSuccess;
        if (twilSuccess !== undefined) respData.twilSuccess = twilSuccess;

        return respData;
    }
}