import React, { Component } from 'react';
import './Sponsors.scss';


import sponsorList from './sponsorList';
import eventSponsorList from './eventSponsorList';

class Sponsors extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount() {

    }
    render() {
        return (
            <section className="section-sponsors" id="sponsors">
                <div className="sponsors container">
                    <div className="row">
                        <div className="col-12 headingContainer">
                            <div className="headingWrapper">
                                <h2 className="heading">Sponsors</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row content">
                        <div className="col-12">
                            {sponsorList.map((tier) => (
                                <div className="tier">
                                    {/* {tier.tier} */}
                                    <div className="sdcHeadingWrapper">
                                        <div className="sdcHeading">
                                            <div className="headingContainer">
                                                <div className="headingWrapper">
                                                    <h3 className="heading">{tier.tier}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="companies">
                                        {tier.companies.map((company) => (
                                            <div className={`company ${company.break ? 'full' : null}`}>
                                                {/* {company.name} */}
                                                {/* {company.src} */}
                                                <a href={company.url} target="_blank">
                                                    {company.src && <img src={company.src} alt={company.name} />}
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="row mediaSponsors">
                        <div className="col-12 headingContainer">
                            <div className="headingWrapper">
                                <h2 className="heading">Event Sponsors</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row content">
                        <div className="col-12">
                            {eventSponsorList.map((tier) => (
                                <div className="tier">
                                    <div className="sdcHeadingWrapper">
                                        <div className="sdcHeading">
                                            <div className="headingContainer">
                                                <div className="headingWrapper">
                                                    <h3 className="heading">{tier.tier}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="companies">
                                        {tier.companies.map((company) => (
                                            <div className={`company ${company.break ? 'full' : null}`}>
                                                <a href={company.url} target="_blank">
                                                    {company.src && <img src={company.src} alt={company.name} />}
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Sponsors;