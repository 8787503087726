import React from 'react';

// importing MyRouts where we located all of our theme
import MyRoutes from './routers/routes'

function App() {
  return (
    <div>
      <MyRoutes />
    </div>
  );
}

export default App;