const sponsorList = [
    {
        tier: "Performance Sponsor",
        companies: [
            {
                name: "XiMu Vocal Academy",
                url: "https://www.xmvocal.com/",
                src: require("../../assets/sponsors/Event Sponsors/XiMu Vocal Academy.jpg").default,
                break: false,
            },
        ]
    },
    {
        tier: "Event Planning",
        companies: [
            {
                name: "CountDown Events",
                url: "https://www.countdownevents.com/",
                src: require("../../assets/sponsors/Event Sponsors/CountDown Events.jpg").default,
                break: false,
            },
        ]
    },

    {
        tier: "Event Coordination",
        companies: [
            {
                name: "Kiwi Production",
                url: "https://kiwivancouver.ca/",
                src: require("../../assets/sponsors/Event Sponsors/Kiwi Production.jpg").default,
                break: false,
            },
        ]
    },

    {
        tier: "Event Production",
        companies: [
            {
                name: "PEAK Technologies",
                url: "https://www.peakt.com/",
                src: require("../../assets/sponsors/Event Sponsors/PEAK.jpg").default,
                break: false,
            },
        ]
    },

    {
        tier: "Event Photography",
        companies: [
            {
                name: "Palette Creative",
                url: "https://www.palettecreative.ca/",
                src: require("../../assets/sponsors/Event Sponsors/Palette Creative.jpg").default,
                break: false,
            },
        ]
    },


];

export default sponsorList;




























