import { Config } from "../Config";
import { GalaFields } from "../model/Gala/GalaFields";
import { IGalaResponse } from "../model/Gala/IGala";
import { AxiosController } from "./AxiosController";


export class GalaController extends AxiosController {
    private PATH: string = 'gala';

    public async getGala() : Promise<IGalaResponse> {
        const galaResponse = await this.axiosGET<IGalaResponse>(
            `${Config.REMOTE_SERVER_ADDR}/${this.PATH}`,
            {
                [GalaFields.galaId]: Config.GALA_ID
            },
        );
        return galaResponse;
    }
}