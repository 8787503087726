import { ResponseSchema } from "../../controllers/AxiosController";
import { Dict } from "../utils";

export const enum PaymentType {
    INIT = '',
    CASH = 'CASH',
    CARD = 'CARD',
    CHEQUE = 'CHEQUE',
}

export interface IGalaTicketSchema {
    _id: string;
    name: Dict<string>;
    desc: Dict<string>;
    totalQty: number;
    qtySold: number;
    hardLimit: boolean;
}

export interface IGalaTicketListingSchema {
    _id: string;
    name: Dict<string>;
    desc: Dict<string>;
    galaTicketMap: Dict<number>;
    totalPrice: number;
    available: boolean;
    isForSale: boolean;

    totalTickets: number;
}

export interface IGalaSchema {
    _id: string;
    storeId: string;
    name: Dict<string>;
    desc: Dict<string>;
    galaTickets: IGalaTicketSchema[];
    galaTicketListings: IGalaTicketListingSchema[];
}

export interface IGalaResponse extends ResponseSchema {
    data: {
        gala: IGalaSchema;
    }
}

export interface IContactSchema {
    fName: string;
    lName: string;
    address: string;
    postalCode: string;
    city: string;
    province: string;
    country: string;
    email: string;
    phone: string;

    pName?: string;
    mealChoice?: string;
    dietRestriction?: string;
    seatingNotes?: string;
    notes?: string;
}

// Gala Ticket Sale
export interface IGalaTicketSaleSchema {
    galaId: string;
    ticketListingIds: string[];
    galaAttendeeIds: string[];
    paymentType: string;
    clientSecret: string | null;
    donationAmount: number;
    price: number;
    total: number;
    saleTime: Date;
    paidTime: Date | null;
    isPaid: boolean;
    fName: string;
    lName: string;
    address: string;
    postalCode: string;
    city: string;
    province: string;
    country: string;
    email: string;
    phone: string;
}

export interface IGalaTicketSaleResponse extends ResponseSchema {
    data: {
        galaTicketSale: IGalaTicketSaleSchema;
    }
}

// Gala Attendee

export interface IGalaAttendeeSchema {

    registered: boolean;
    galaId : string;
    galaTicketId: string;
    galaRaffles: [];
    galaDonations: [];
    fName: string | null;
    lName: string | null;
    address: string | null;
    postalCode: string | null;
    city: string | null;
    province: string | null;
    country: string | null;
    email: string | null;
    phone: string | null;

}

export interface IGalaAttendeeResponse extends ResponseSchema {
    data: {
        galaAttendee: IGalaAttendeeSchema;
    }
}