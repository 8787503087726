import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import Tickets from '../components/Tickets/Tickets';
import Hero from '../components/Hero/Hero';
class BuyTickets extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <Hero />
                <Tickets />
                <Footer />
                <ModalMenu />
                <Scrollup />
            </div>
        );
    }
}

export default BuyTickets;