import { Component } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Config } from '../../Config';
import { GalaAuctionController } from '../../controllers/galaAuctionController';
import { amountToString } from '../Tickets/Tickets';
import './AuctionPreview.scss';
SwiperCore.use([Navigation]);

const initData = {
    pre_heading: "Auctions",
    heading: "Auction Preview",
    btnText: "View All"
}

class AuctionPreview extends Component {
    state = {
        initData: {},
        rows: 3,
        data: []
    }
    componentDidMount() {
        const galaAuctionController = new GalaAuctionController();
        galaAuctionController.getAllByGala(Config.GALA_ID).then((dataArr) => {
            dataArr.sort((a, b) => b.startPrice - a.startPrice)
            this.setState({
                initData: initData,
                data: dataArr
            })
        })

        if (window.innerWidth < 700) {
            console.log(this.state.rows);
            this.setState({
                initData: this.state.initData,
                data: this.state.data,
                rows: 1,
            })
            return;
        }

        this.setState({
            initData: this.state.initData,
            data: this.state.data,
            rows: 3,
        })

        window.addEventListener('resize', () => {

            if (window.innerWidth < 700) {
                console.log(this.state.rows);
                this.setState({
                    initData: this.state.initData,
                    data: this.state.data,
                    rows: 1,
                })
                return;
            }

            this.setState({
                initData: this.state.initData,
                data: this.state.data,
                rows: 3,
            })
        })

    }
    render() {
        return (
            <section className="section-auction-preview" id="auction">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro d-flex justify-content-between align-items-end m-0">
                                <div className="intro-content">
                                    <h3 className="mt-3 mb-0">{this.state.initData.heading}</h3>
                                </div>
                                {/* <div className="intro-btn">
                                    <a className="btn content-btn" href="/auctions">{this.state.initData.btnText}</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="auctions-slides">
                        <div className="swiper-container slider-mid items">
                            <div>
                                {/* Single Slide */}
                                <Swiper
                                    modules={[Navigation]}
                                    slidesPerView={this.state.rows}
                                    slidesPerColumn={2}
                                    slidesPerColumnFill="row"
                                    pagination={{ clickable: true }}
                                    navigation={{ clickable: true }}
                                >
                                    {this.state.data.map((item, idx) => {
                                        return (
                                            <SwiperSlide>
                                                <div className="card">
                                                    <div className="image-over">
                                                        {/* <a> */}
                                                        {item.imgUrls.length > 0 && (
                                                            <img className="card-img-top" src={item.imgUrls[0]} alt="" />
                                                        )}
                                                        {/* </a> */}
                                                    </div>
                                                    {/* Card Caption */}
                                                    <div className="card-caption col-12 p-0">
                                                        {/* Card Body */}
                                                        <div className="card-body">

                                                            {/* <a href="/item-details"> */}
                                                            <h6 className="mb-0">{item.name.en}</h6>
                                                            {/* </a> */}
                                                            <div className="card-bottom d-flex justify-content-between">
                                                                <span>Starting:</span>
                                                                <span><b>{amountToString(item.startPrice)}</b></span>
                                                            </div>
                                                            {/* <div className="card-bottom d-flex justify-content-between">
                                                                <span>Minimum Increase:</span>
                                                                <span><b>{item.minIncrease}</b></span>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                            {/* <div className="swiper-pagination" /> */}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AuctionPreview;