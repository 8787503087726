import React, { Component } from 'react';
import './AboutBrivia.scss';

class AboutSDC extends Component {
    state = {}
    componentDidMount() {
        //
    }
    render() {
        return (
            <section className="section-about-brivia" id="brivia">
                <div className="about-brivia container">
                    <div className="row">
                        <div className="col-12 headingContainer">
                            <div className="headingWrapper">
                                <h2 className="heading">About Brivia</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row content">
                        <div className="col-12">
                            <p>
                                <a href="https://briviagroup.ca/" target="_blank" rel="noopener noreferrer" style={{ color: "white", textDecoration: "underline" }}>Brivia Group</a>
                                {" "}
                                is a real estate development and investment company with an expanding portfolio of major projects in the Greater Montreal Area, Greater Toronto Area, Vancouver, Quebec City and other regions of Quebec province. Founded in 2000, Brivia Group has the expertise, track record and business networks needed to fulfill bold projects such as Curv, 1 Square Phillips, Mansfield, YUL, QuinzeCent and LB9, meeting the expectations of its diverse clienteles. With a deep sense of community, Brivia Group is devoted to support causes that contribute to the development of the health care sector, propel the growth of our future generations and promote social diversity and equality.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AboutSDC;