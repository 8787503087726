import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import curvImg from '../../assets/about/curv.jpg';
import './AboutCurv.scss';
class AboutCurv extends Component {
    state = {}
    componentDidMount() {
        //
    }
    render() {
        return (
            <section className="section-about-curv" id="curv">
                <div className="about-curv container"  >
                    <div className="row">
                        <div className="col-12 headingContainer">
                            <div className="headingWrapper">
                                <h2 className="heading">About CURV</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row content">
                        <div className="col-12">
                            <img src={curvImg} alt="curv" />
                            <p>
                                Committed to a mission of sustainability, Brivia Group is the leader of a new wave of developments and real estate focused on energy efficiency. Awarded the esteemed Énergir prize at the 35th Domus Awards for their YUL Condominiums’ dynamic and green design, Brivia continues to demonstrate leadership as Canada’s most progressive developer with CURV.
                            </p>

                            <p>
                                CURV is a one-of-a-kind development, using Vancouver as the backdrop for the world’s tallest Passive House building at 60-storeys and at the forefront of energy efficient building design—in line with Vancouver’s identity as the Greenest City. CURV will be a cutting-edge residential housing that minimizes carbon emissions and energy usage by consuming up to 90% less heating and cooling energy than conventional buildings, leading to a reduction in its ecological footprint. Named for its curved architectural design, CURV takes the form of a new growth of nature; a symbol of sustainability and environmental hope.
                            </p>

                            <p>
                                To change our society for the better, there needs to be a coordinated effort across different fields by those who are motivated to take charge. Brivia and SDC are two of these leaders who share one vision, one mission, one ambition: to be the innovation that helps the children now and in the years ahead.
                            </p>

                            <p>
                                Brivia and SDC united represent the future—a future that we can be proud of and a future built to give back. Collectively, we seek to establish a home for Canadians that is progressive, comfortable, and safe while striving to raise the quality of life for all.
                            </p>

                            <p>
                                Brivia and SDC work for a better and brighter community for future generations that is sustainable, empowering, and full of love. Together, we light the flame of tomorrow to protect the hopes of today’s children.
                            </p>

                            <a href="https://thecurv.ca/" style={{ color: "white", textDecoration: "underline" }} target="_blank" rel="noreferrer">https://thecurv.ca/</a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AboutCurv;