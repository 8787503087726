import { Config } from "../Config";
import { GalaAttendeefields as GalaAttendeeFields, GalaFields } from "../model/Gala/GalaFields";
import { IContactSchema, IGalaAttendeeResponse } from "../model/Gala/IGala";
import { AxiosController } from "./AxiosController";


export class GalaAttendeeController extends AxiosController {
    private PATH: string = 'gala/attendee';

    public async getGalaAttendee(
        galaAttendeeId: string,
    ) : Promise<IGalaAttendeeResponse> {
        const galaAttendeeResponse = await this.axiosGET<IGalaAttendeeResponse>(
            `${Config.REMOTE_SERVER_ADDR}/${this.PATH}`,
            {
                [GalaAttendeeFields.galaAttendeeId]: galaAttendeeId
            },
        );
        return galaAttendeeResponse;
    }

    public async registerAttendee(
        galaAttendeeId: string,
        contact: IContactSchema,
    ): Promise<IGalaAttendeeResponse> {
        if (
            contact.mealChoice === undefined || contact.dietRestriction === undefined
            || contact.seatingNotes === undefined || contact.notes === undefined
        ) { 
            throw new Error('Some fields undefined');
        }
        const galaAttendeeResponse = await this.axiosPOST<IGalaAttendeeResponse>(
            `${Config.REMOTE_SERVER_ADDR}/${this.PATH}/register`,
            {
                [GalaAttendeeFields.galaAttendeeId]: galaAttendeeId,
                [GalaAttendeeFields.fName]: contact.fName,
                [GalaAttendeeFields.lName]: contact.lName,
                [GalaAttendeeFields.address]: contact.address,
                [GalaAttendeeFields.postalCode]: contact.postalCode,
                [GalaAttendeeFields.city]: contact.city,
                [GalaAttendeeFields.province]: contact.province,
                [GalaAttendeeFields.country]: contact.country,
                [GalaAttendeeFields.email]: contact.email,
                [GalaAttendeeFields.phone]: contact.phone,

                [GalaAttendeeFields.pName]: contact.pName,
                [GalaAttendeeFields.mealChoice]: contact.mealChoice,
                [GalaAttendeeFields.dietRestriction]: contact.dietRestriction,
                [GalaAttendeeFields.seatingNotes]: contact.seatingNotes,
                [GalaAttendeeFields.notes]: contact.notes,

            },
        );
        return galaAttendeeResponse;
    }
}