const sponsorList = [
    {
        tier: "Title",
        companies: [
            {
                name: "Curv",
                url: "https://thecurv.ca/",
                src: require("../../assets/sponsors//Gala Sponsors/Title/Curv.jpg").default,
                break: true,
            },
            {
                name: "BRIVIA Group",
                url: "https://briviagroup.ca/",
                src: require("../../assets/sponsors/Gala Sponsors/Title/BriviaGroup.jpg").default,
                break: false,
            },
            {
                name: "Henson Development",
                url: "https://hensondevelopments.com/",
                src: require("../../assets/sponsors/Gala Sponsors/Title/Henson.jpg").default,
                break: false,
            }
        ],
    },
    {
        tier: "Presenting",
        companies: [
            {
                name: "BakerWest Real Estate Incorporated",
                url: "https://bakerwest.com/",
                src: require("../../assets/sponsors/Gala Sponsors/Presenting/BakerWest.jpg").default,
                break: false,
            },
            {
                name: "Vancouver Career College",
                url: "https://www.career.college/",
                src: require("../../assets/sponsors/Gala Sponsors/Presenting/Vancouver Career College.jpg").default,
                break: false,
            },
        ]
    },
    {
        tier: "Diamond",
        companies: [
            {
                name: "IGG",
                url: "https://www.igg.com/",
                src: require("../../assets/sponsors/Gala Sponsors/Diamond/IGG.jpg").default,
                break: false,
            },
            {
                name: "Truedan Canada",
                url: "https://jenjudan-canada.com/main/",
                src: require("../../assets/sponsors/Gala Sponsors/Diamond/Truedan.jpg").default,
                break: false,
            },
        ]
    },
    {
        tier: "Platinum",
        companies: [
            {
                name: "Periphery Digital ",
                url: "https://www.peripherydigital.com/",
                src: require("../../assets/sponsors/Gala Sponsors/Platinum/Periphery.jpg").default,
                break: false,
            },
            {
                name: "Hantec Group",
                url: null,
                src: require("../../assets/sponsors/Gala Sponsors/Platinum/Hantec Group.jpg").default,
                break: false,
            },
            {
                name: "Jenny Peckham ",
                url: "https://jennypackham.com/",
                src: require("../../assets/sponsors/Gala Sponsors/Platinum/Jenny Peckham.jpg").default,
                break: false,
            },
            {
                name: "Quantum Academy",
                url: null,
                src: require("../../assets/sponsors/Gala Sponsors/Platinum/Quantum Academy.jpg").default,
                break: false,
            },
        ]
    },
    {
        tier: "Gold",
        companies: [
            {
                name: "XR Auto Club",
                url: null,
                src: require("../../assets/sponsors/Gala Sponsors/Gold/XR Auto Club.jpg").default,
                break: false,
            },
            {
                name: "EVA Airway",
                url: "https://www.evaair.com/en-global/index.html",
                src: require("../../assets/sponsors/Gala Sponsors/Gold/Eva Airway.jpg").default,
                break: false,
            },
            {
                name: "THUNDERBOLT LED & SIGN LTD.",
                url: "https://www.thunderboltsign.com/",
                src: null,
                break: false,
            },
            {
                name: "JULIA THE POSTPARTUM DOULA INC",
                url: "https://www.juliaskitchen.org/",
                src: require("../../assets/sponsors/Gala Sponsors/Gold/Julia_s Kitchen.jpg").default,
                break: false,
            },
            {
                name: "LUROU FAN TASTY FRANCHISE INC",
                url: "https://luroufantasty.wixsite.com/luroufan-tasty",
                src: require("../../assets/sponsors/Gala Sponsors/Gold/Lurou.jpg").default,
                break: false,
            },
            {
                name: "Judith Buist",
                url: "https://www.instagram.com/jhomesca/?hl=en",
                src: require("../../assets/sponsors/Gala Sponsors/Gold/Judith Buist.jpg").default,
                break: false,
            },

            {
                name: "CIBC",
                url: "https://www.cibc.com/en/special-offers/effortless-banking.html?utrc=S186:101&gclid=CjwKCAjw3K2XBhAzEiwAmmgrAvDm7tDWGcGo9R5mz0Nf4YGjtgetXfQf0krlCrlH80RVHHGKel-97xoCZXEQAvD_BwE&gclsrc=aw.ds",
                src: require("../../assets/sponsors/Gala Sponsors/Gold/CIBC.jpg").default,
                break: false,
            },
            {
                name: "Skyllen Pacific",
                url: "https://www.skyllenpacific.com/",
                src: require("../../assets/sponsors/Gala Sponsors/Gold/Skyllen Pacific.jpg").default,
                break: false,
            },
            {
                name: "ShiNail",
                url: null,
                src: require("../../assets/sponsors/Gala Sponsors/Gold/ShiNail.JPG").default,
                break: false,
            },
            {
                name: "Grand Ocean International Trading Ltd",
                url: null,
                src: require("../../assets/sponsors/Gala Sponsors/Gold/Grand Ocean International Trading.jpg").default,
                break: false,
            },
            // Media
            {
                name: "AM 1320",
                url: "https://am1320.com/",
                src: require("../../assets/sponsors/Media Sponsors/Gold/AM1320.jpg").default,
                break: false,
            },
            {
                name: "Novus TV",
                url: "https://www.novusnow.ca/television/",
                src: require("../../assets/sponsors/Media Sponsors/Gold/Novus.jpg").default,
                break: false,
            },
        ]
    },
    {
        tier: "Silver",
        companies: [
            {
                name: "UKnead",
                url: null,
                src: require("../../assets/sponsors/Gala Sponsors/Silver/UKnead.jpg").default,
                break: false,
            },
            {
                name: "Visual Art Academy",
                url: null,
                src: require("../../assets/sponsors/Gala Sponsors/Silver/Visual Art Academy.jpg").default,
                break: false,
            },

            // Media
            {
                name: "MingPao",
                url: "http://www.mingpaocanada.com/van/",
                src: require("../../assets/sponsors/Media Sponsors/Silver/MingPao.jpg").default,
                break: false,
            },
            {
                name: "Fairchild TV",
                url: "https://www.fairchildtv.com/",
                src: require("../../assets/sponsors/Media Sponsors/Silver/Fairchild.jpg").default,
                break: false,
            },
            {
                name: "Talentvision",
                url: "https://www.talentvisiontv.com/",
                src: null,
                break: false,
            },
            {
                name: "VanBox Media",
                url: null,
                src: require("../../assets/sponsors/Media Sponsors/Silver/Vanbox.jpeg").default,
                break: false,
            },
        ]

    }
];

export default sponsorList;




























