import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Footer from '../components/Footer/Footer';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import Redeem from '../components/Redeem/Redeem';

class RedeemTicket extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <Hero />
                <Redeem galaAttendeeId={this.props.match.params.id} />
                <Footer />
                <ModalMenu />
                <Scrollup />
            </div>
        );
    }
}

export default RedeemTicket;