import { Config } from '../Config';
import { AuctionTypeEnum } from '../model/Gala/galaAuction/galaAuctionFields';
import { IGalaAuction } from '../model/Gala/galaAuction/IGalaAuction';
import { AxiosController, ResponseSchema } from './AxiosController';

interface GalaAuctionArrayResponse extends ResponseSchema {
    data: {
        galaAuctions: IGalaAuction[],
    }
}

export class GalaAuctionController extends AxiosController {

    public async getAllByGala(
        galaId: string,
    ) : Promise<IGalaAuction[]> {
        const resp : GalaAuctionArrayResponse = await this.axiosGET(`${Config.REMOTE_SERVER_ADDR}/gala/auction/all`, {
            galaId: galaId,
            auctionType: AuctionTypeEnum.silent,
        });
        const galaAuction: IGalaAuction[] = resp.data.galaAuctions;
        return galaAuction;
    }
}
